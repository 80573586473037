import React from 'react'
import styled from 'styled-components'

const QuoteWrapper = styled.div`
  margin: 0 auto;
  max-width: 1280px;
  padding: 50px 0;
  display: flex;
`

const SingleQuote = styled.h1`
  color: #d3a04d;
  font-size: 100px;
  font-weight: 600;
  line-height: 24px;
  text-transform: uppercase;
  text-align: justify;
  margin-right: 60px;

  @media only screen and (max-width: 600px) {
    display: none;
  }
`

const TextWrapper = styled.div`
  @media only screen and (max-width: 600px) {
    padding: 20px;
  }
`

const Text = styled.h1`
  font-size: 20px;
  font-weight: 800;
  line-height: 24px;
`

const Author = styled.h1`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`

export default function Quote(props) {
  const { text, author } = props
  return (
    <QuoteWrapper>
      <SingleQuote>„</SingleQuote>
      <TextWrapper>
        <Text>{text}</Text>
        <hr style={{ height: 3, backgroundColor: '#d3a04d' }} />
        <Author>
          <i>{author}</i>
        </Author>
      </TextWrapper>
      <SingleQuote>“</SingleQuote>
    </QuoteWrapper>
  )
}
