import React from 'react'
import styled from 'styled-components'
import GoogleMaps from '../googlemaps'
import HeadlineTwo from '../headlinetwo'
import HeadlineExtra from '../headlineextra'
import Marker from '../../images/marker.png'
import Letter from '../../images/letter.png'
import Phone from '../../images/phone.png'

const Wrapper = styled.div`
  padding-top: 40px;
  display: flex;
  margin: 0;
`

const LeftCol = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  background-color: #d3a04e;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
  @media only screen and (max-width: 1000px) {
    width: 100%;
  }
`

const RightCol = styled.div`
  width: 50%;
  @media only screen and (max-width: 1000px) {
    display: none;
  }
`

const Icon = styled.div`
  height: 50px;
  padding-right: 20px;
  display: flex;
  align-items: center;
`

const Image = styled.img`
  margin: 0;
`

const LeftColInside = styled.div`
  max-width: 620px;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media only screen and (max-width: 1000px) {
    width: initial;
    padding: 5px;
  }
`

const UnderlineFooter = styled.div`
  width: 132px;
  height: 3px;
  background-color: #fff;
  margin-bottom: 40px;
`

const AdressRow = styled.div`
  display: flex;
  padding: 18px 0;
  height: 100px;
  align-items: center;
`

const Text = styled.div`
  color: #ffffff;
  font-family: 'Open Sans';
  font-size: 15px;
  font-weight: 600;
  line-height: 26px;
`

export default function Footer() {
  return (
    <div>
      <Wrapper>
        <LeftCol>
          <LeftColInside>
            <HeadlineTwo style={{ textAlign: 'left' }}>
              <HeadlineExtra style={{ color: '#fff' }}>Kontakt</HeadlineExtra>
              Information
            </HeadlineTwo>
            <UnderlineFooter />
            <AdressRow>
              <Icon>
                <Image src={Marker} />
              </Icon>
              <Text>
                Belgradstraße 55
                <br />
                80796 München
              </Text>
            </AdressRow>
            <AdressRow>
              <Icon>
                <Image src={Letter} />
              </Icon>
              <Text>
                <a
                  href="mailto:info@steuerkanzlei-ruberto.de"
                  style={{ textDecoration: 'none', color: '#fff' }}
                >
                  info@steuerkanzlei-ruberto.de
                </a>
                <br />
                <a
                  href="mailto:kanzlei@steuerberatung-ruberto.de"
                  style={{ textDecoration: 'none', color: '#fff' }}
                >
                  kanzlei@steuerberatung-ruberto.de
                </a>
              </Text>
            </AdressRow>
            <AdressRow>
              <Icon>
                <Image src={Phone} />
              </Icon>
              <Text>
                +(0) 49 89 - 526 284
                <br />
                +(0) 49 89 - 131 007
              </Text>
            </AdressRow>
          </LeftColInside>
        </LeftCol>
        <RightCol>
          <GoogleMaps />
        </RightCol>
      </Wrapper>
    </div>
  )
}
