import styled from 'styled-components'

const Underline = styled.div`
  width: 132px;
  height: 3px;
  background-color: #d3a04e;
  margin: 0 auto;
  margin-bottom: 20px;
`
export default Underline
