import React, { useState } from 'react'
import styled from 'styled-components'

import MapPlaceholder from '../../images/ruberto-map-blur.jpg'

const MapWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  
`

const MapBlur = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  position: relative;

`

const MapBlurImage = styled.img`
  position: absolute;
  filter: brightness(0.75);
  z-index: -1;
  width: 100%;
  height: 100%;
  margin: 0;
`

const MapButton = styled.button`
  height: fit-content;
  color: white;
  background-color: #d3a04e;
  border: none;
  text-transform: uppercase;
  font-weight: bold;
  padding: 10px;
  cursor: pointer;
`

const Disclaimer = styled.p`
  max-width: 75%;
  margin: 0 auto;
  text-align: center;
  padding-bottom: 20px;
`

export default function GoogleMaps() {
  const windowAvailable = (typeof window !== 'undefined')
  const [mapAccepted, setAccepted] = useState(
    windowAvailable && JSON.parse(localStorage?.getItem('mapAccepted')) || false
  );

  const accept = () => {
    windowAvailable && localStorage.setItem('mapAccepted', true)
    setAccepted(true)
    console.log('after', mapAccepted)
  }

  console.log(mapAccepted)
  return (
    mapAccepted ?
      <iframe
      title="GoogleMaps"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2661.1218100084216!2d11.571541315894551!3d48.16573355717812!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479e75cfe3283dad%3A0x42e2a9a62290c1b9!2sBelgradstra%C3%9Fe+55%2C+80796+M%C3%BCnchen!5e0!3m2!1sde!2sde!4v1544714439444"
      width="100%"
      height="100%"
      frameBorder="0"
      style={{ border: 0 }}
      allowFullScreen
    /> :
    <>
      <MapWrapper>
        <MapBlur>
        <MapBlurImage src={MapPlaceholder} alt="map-placeholder" />
        <MapButton onClick={accept}>Karte laden *</MapButton>
      </MapBlur>
      <Disclaimer>*  Mit dem Laden der Karte akzeptierst du die Datenschutzerklärung von Google. <a href="https://policies.google.com/privacy?hl=de">Mehr erfahren</a></Disclaimer>
      </MapWrapper>

    </>
  )
}
