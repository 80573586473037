import styled from 'styled-components'

const MegaCopytext = styled.p`
  color: #fff;
  font-family: 'Open Sans';
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.8rem;
  @media (max-width: 600px) {
    font-size: 1rem;
  }
`

export default MegaCopytext
