import React from 'react'
import Layout from '../components/layout'
import HeaderSubsite from '../components/headersubsite'
import FullImagetext from '../components/fullimagetext'
import HeadlineTwo from '../components/headlinetwo'
import HeadlineExtra from '../components/headlineextra'
import Underline from '../components/underline'
import Copytext from '../components/copytext'
import Footer from '../components/footer'
import SubFooter from '../components/subfooter'
import Quote from '../components/quote'

import Beratung2 from '../images/steuerberatung-srd.jpg'
import VertrauenImage from '../images/vertrauen.jpg'
import VerbindlichImage from '../images/verbindlich.jpg'
import Verstaendlich from '../images/verstaendlich.jpg'

const WertePage = () => (
  <Layout>
    <HeaderSubsite
      headline="Unsere Werte"
      subline="Verbindlich. Vertrauensvoll. Verständlich."
      bg={Beratung2}
    />
    <br />
    <br />
    <br />
    <HeadlineTwo>
      <HeadlineExtra color="#d3a04e">UNSERE</HeadlineExtra> WERTE
    </HeadlineTwo>
    <Underline />
    <div style={{ maxWidth: 1280, margin: '0 auto' }}>
      <Copytext>
        Manchmal sagen Werte über die Kultur einer Kanzlei deutlich mehr als
        viele schöne Worte. Ein gemeinsamer Wertekanon, der für das gesamte Team
        gilt, trägt darüber hinaus dazu bei, die Prozesse zwischen Kanzlei und
        Mandanten effizienter und transparenter zu machen. Und damit unterm
        Strich das gemeinsam definierte Ziel schneller zu erreichen.
      </Copytext>
    </div>
    <br />
    <br />
    <FullImagetext title="Verbindlich." image={VertrauenImage}>
      Das bedeutet für uns: Ziele, Termine und Vereinbarungen sind für uns mehr
      als ein Versprechen – sie werden konsequent eingehalten. Denn erst auf
      diesem Fundament entsteht eine tragfähige Partnerschaft, die von
      gegenseitigem Respekt, Vertrauen und Offenheit, aber auch von
      Verbindlichkeit, Zielorientierung und Konsequenz geprägt ist.
    </FullImagetext>
    <FullImagetext
      direction="row-reverse"
      title="Vertrauensvoll."
      image={VerbindlichImage}
      primary={false}
    >
      Mit Ihrem Mandat vertrauen Sie uns etwas sehr Wertvolles an. Dieses
      Vertrauen gilt es zu rechtfertigen. Tag für Tag. Mit fundiertem Know-how
      und ausgeprägtem Engagement, aber auch mit dem notwendigen
      Fingerspitzengefühl und Einfühlungsvermögen für Ihre individuellen Belange
      wollen wir das in uns gesetzte Vertrauen zurückzahlen. Und damit Ihren
      Erfolg nachhaltig sicherstellen.
    </FullImagetext>
    <FullImagetext title="Verständlich." image={Verstaendlich}>
      Die Komplexität des heutigen Steuerrechts und seinen spezifischen
      Beratungsanforderungen ist heute größer denn je. Umso wichtiger ist es,
      eine Sprache zu sprechen, die auch verstanden wird. Deshalb verstecken wir
      uns nicht hinter Fachausdrücken, die ausschließlich Steuer- und
      Wirtschaftsexperten verstehen, sondern kommunizieren klar, eindeutig und
      verständlich.
    </FullImagetext>
    <br />
    <br />
    <Quote
      text="Um eine Einkommensteuererklärung abgeben zu können, muss man Philosoph sein. Es ist zu schwierig für einen Mathematiker."
      author="(Albert Einstein)"
    />
    <Footer />
    <SubFooter />
  </Layout>
)

export default WertePage
