import React from 'react'
import styled from 'styled-components'

const FullWidthDiv = styled.div`
  position: relative;
  background: url(${props => props.bg}) no-repeat center center;

  background-size: cover;
  height: 300px;
`

const InnerWrapper = styled.div``

const Overlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  background: rgba(204, 204, 204, 0.5);
  ${InnerWrapper} {
    margin: 0 auto;
    max-width: 1280px;
    padding: 1.45rem 1.0875rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 300px;
  }
`

//  line-height: 28px;
const Headline = styled.h1`
  color: #fff;
  font-family: 'Roboto';
  font-size: 65px;
  font-weight: 900;
  text-transform: uppercase;
`

//line-height: 20px;
const Subline = styled.h4`
  color: #fff;
  font-family: 'Roboto';
  font-size: 34px;
  font-weight: lighter;
`

export default function HeaderSubsite(props) {
  const { headline, subline } = props
  return (
    <FullWidthDiv bg={props.bg}>
      <Overlay>
        <InnerWrapper>
          <Headline>{headline}</Headline>
          <Subline>{subline}</Subline>
        </InnerWrapper>
      </Overlay>
    </FullWidthDiv>
  )
}
