import styled from 'styled-components'

const HeadlineTwo = styled.h2`
  font-size: 1.875rem;
  text-align: center;
  color: #333333;
  font-weight: 900;
  line-height: 28px;
  text-transform: uppercase;
`

export default HeadlineTwo
