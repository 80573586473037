import styled from 'styled-components'

const SuperHeadline = styled.h1`
  font-family: 'Roboto';
  font-size: 1.8rem;
  font-weight: 900;
  color: #fff;
  margin-bottom: 40px;

  @media (max-width: 600px) {
    font-size: 1.4rem;
  }
`

export default SuperHeadline
