import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

const InfoText = styled.div`
  color: #8d8c8c;
  font-family: 'Open Sans';
  font-size: 15px;
  font-weight: 400;
  line-height: 26px;
`

const Highlight = styled.span`
  color: #d3a04e;
  font-weight: bold;
`

export default function SubFooter() {
  return (
    <div
      style={{
        backgroundColor: '#2d2c2c',
        paddingTop: 20,
        paddingBottom: 20,
        textAlign: 'center',
      }}
    >
      <InfoText>
        <Link to="/impressum" style={{ color: '#fff', textDecoration: 'none' }}>
          Impressum
        </Link>{' '}
        |{' '}
        <Link
          to="/datenschutz"
          style={{ color: '#fff', textDecoration: 'none' }}
        >
          Datenschutz
        </Link>{' '}
        | Design & Development &copy; copyright 2019 by{' '}
        <Highlight>SB Konzept GmbH</Highlight>
      </InfoText>
    </div>
  )
}
