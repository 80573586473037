import styled from 'styled-components'

const FixedImage = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: ${({ ratio }) => ratio * 100}%;
  > img,
  > .gatsby-image-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
  }
`

FixedImage.defaultProps = {
  ratio: 9 / 16,
}

export default FixedImage
