import React from 'react'
import styled from 'styled-components'
import SuperHeadline from '../superheadline'
import MegaCopytext from '../megacopytext'
import FixedImage from '../fixedimage'

const FullWrapper = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: ${props => props.direction};
  @media only screen and (max-width: 1000px) {
    flex-wrap: wrap;
  }
`

const LeftCol = styled.div`
  width: 40%;
  @media only screen and (max-width: 1000px) {
    width: 100%;
  }
`

const RightCol = styled.div`
  width: 60%;
  padding: 50px 100px;
  background: ${props => (props.primary ? '#d3a04e' : '#c08c41')};
  @media only screen and (max-width: 1000px) {
    width: 100%;
  }
`

export default function FullImageText(props) {
  const { image, title, children, primary = true } = props
  return (
    <div>
      <FullWrapper direction={props.direction}>
        <LeftCol>
          <FixedImage>
            <img src={image} alt="" />
          </FixedImage>
        </LeftCol>
        <RightCol primary={primary}>
          <SuperHeadline>{title}</SuperHeadline>
          <MegaCopytext>{children}</MegaCopytext>
        </RightCol>
      </FullWrapper>
    </div>
  )
}
