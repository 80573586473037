import styled from 'styled-components'

const Copytext = styled.div`
  color: #898989;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-align: center;
  margin-bottom: 20px;
  font-family: 'Open Sans';
`
export default Copytext
